import { SPLink } from '../components/SPLink'

import React from 'react'

type Props = {
  id: string
  name: string
  imageSrc: string
  mainCarMake: string
  carsCount: number
}

const RecommendedModelCard = (props: Props) => {
  let url = `/list?make=${props.mainCarMake}&model=${props.id}`

  // if showing Rapid, show also Rapid Spaceback
  if (props.id === 'model_573') {
    url = url.concat('&model=model_1695')
  }

  return (
    <div className='saps-recommended-models__item'>
      <div className='saps-recommended-models__image-wrapper'>
        <div style={{ height: 170, offset: 100 }}>
          <img
            className='img-fluid saps-recommended-models__image'
            src={props.imageSrc}
            alt={`${props.id}-${props.name}`}
            loading='lazy'
          />
        </div>
      </div>
      <div className='saps-recommended-models__name'>{props.name}</div>
      <div className='saps-recommended-models__numberOfItems'>({props.carsCount}×)</div>
      <SPLink href={url} className='saps-recommended-models__item-link' />
    </div>
  )
}

export default RecommendedModelCard
