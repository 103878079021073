import {
  API_URL,
  COUNTRY_CODE,
  LANGUAGE,
  getTranslation,
  oneToFive,
} from '../splus-lib/utils/languageUtils'
import {
  BasicFilterBodyTypeGroups,
  InputRadioDropdown,
  InputSlider,
  LinkButton,
} from '../splus-lib/components'
import {
  BasicFilter_InitFetchDataQueryQuery,
  BasicFilter_RefetchInputsQueryQuery,
} from './BasicFilter.__generated__'
import { ReactComponent as IconCabriolet } from './svg/bodyTypes/cabriolet.svg'
import { ReactComponent as IconCombi } from './svg/bodyTypes/combi.svg'
import { ReactComponent as IconHatchback } from './svg/bodyTypes/hatchback.svg'
import { ReactComponent as IconSUV } from './svg/bodyTypes/suv.svg'
import { ReactComponent as IconSedan } from './svg/bodyTypes/sedan.svg'
import {
  IsMobile,
  basicFilter,
  buildUrl,
  notNullable,
  orderArrayOfObjects,
  sortByNestedName,
} from '../splus-lib/utils'
import { ReactRelayContext, fetchQuery, graphql } from 'react-relay'
import { SPLink } from '../components/SPLink'
import { gql, request } from 'graphql-request'
import { gtmDataLayerPush } from '../splus-lib/utils/googleTagManager/utils'
import { useComponentDidMount } from '../splus-lib/utils/hooks/hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'

const mappedCarBodyIconToName = {
  suv: IconSUV,
  sedan: IconSedan,
  combi: IconCombi,
  hatchback: IconHatchback,
  kabrio: IconCabriolet,
}

type Props = {
  basicView: boolean
}
const BasicFilter = ({ basicView }: Props) => {
  const { environment } = useContext(ReactRelayContext)!

  const [carMakesList, setCarMakesList] = useState(
    [] as {
      id: string
      name: string
      carsCount: number
    }[]
  )
  const [carMakeValue, setCarMakeValue] = useState(null as null | string)
  const [carModelValue, setCarModel] = useState(null as null | string)
  const [carModelsDropdownValues, setCarModelsDropdownValues] = useState(
    [] as {
      id: string
      name: string
      carsCount: number
    }[]
  )
  const [carPriceFromValue, setCarPriceFrom] = useState(0)
  const [carPriceToValue, setCarPriceTo] = useState(null as null | undefined | number)
  const [carMileageFromValue, setCarMileageFrom] = useState(0)
  const [carMileageToValue, setCarMileageTo] = useState(null as null | undefined | number)
  const [firstRegistrationFromValue, setFirstRegistrationFrom] = useState(0)
  const [firstRegistrationToValue, setFirstRegistrationTo] = useState(
    null as null | undefined | number
  )
  const [carBodyTypesList, setCarBodyTypesList] = useState(
    [] as NonNullable<BasicFilter_RefetchInputsQueryQuery['bodyTypeCodeBook']>
  )
  const [selectCarBodyType, setSelectCarBodyType] = useState(null as string | null)
  const [carsCount, setCarsCount] = useState(null as number | null | undefined)
  const [carFuelTypesList, setCarFuelTypesList] = useState(
    [] as NonNullable<BasicFilter_RefetchInputsQueryQuery['fuelTypeCodeBook']>
  )
  const [carFuelType, setCarFuelType] = useState('' as string | null)
  const [carBodyTypeByParamGroupsList, setCarBodyTypeByParamGroupsList] = useState(
    [] as NonNullable<BasicFilter_RefetchInputsQueryQuery['carBodyTypes']>
  )
  const [staticBodyTypeGroupsEnum, setStaticBodyTypeGroupsEnum] = useState(
    [] as BasicFilter_InitFetchDataQueryQuery['clientProfile']['bodyTypeGroups']
  )
  const [selectedCarBodyTypeGroupNames, setSelectedCarBodyTypeGroupNames] = useState([] as string[])
  const [carModelZeroOptionsLabel, setCarModelZeroOptionsLabel] = useState('')

  const carModelDropdownEmptyLabel = getTranslation('basic.filter.dropdown.model.choose')
  const defaultSubmitButtonText = getTranslation('basic.filter.submit.button.text.default')

  const isMobile = useContext(IsMobile)

  const MIN_FIRST_REG = 1990
  const MAX_FIRST_REG = new Date().getFullYear()

  useComponentDidMount(async () => {
    const carMakesQueryGql = graphql`
      query BasicFilter_initFetchDataQuery($filter: CarFilterInput) {
        clientProfile(clientId: "SKODAPLUS") {
          bodyTypeGroups {
            name
            includedBodyTypes {
              id
            }
          }
          preferredCarMakes
          mainCarMake {
            id
          }
        }
        carMakes(includeUnused: false, filter: $filter) {
          id
          name
          carsCount
        }
      }
    `

    const initFetchDataResponse = await fetchQuery<{
      variables: any
      response: BasicFilter_InitFetchDataQueryQuery
    }>(environment, carMakesQueryGql, {
      filter: {
        ...basicFilter(
          // @ts-expect-error
          params
        ),
        make: null,
        model: null,
        regionCountry: COUNTRY_CODE,
      },
    }).toPromise()
    /*
    // TODO: fuck relay
    const initFetchDataResponse = await request<
      BasicFilter_initFetchDataQueryQuery
    >(
      API_URL,
      // const initFetchDataResponse = await fetchQuery<{response: BasicFilter_initFetchDataQuery}>(
      //   environment,
      carMakesQueryGql,
      {
        filter: {
          ...basicFilter(
            // @ts-expect-error
            params
          ),
          make: null,
          model: null,
          regionCountry: COUNTRY_CODE,
        },
      }
    )
    */
    const makesListWithCarsCount = initFetchDataResponse?.carMakes

    const orderedMakesList = orderArrayOfObjects(
      makesListWithCarsCount ?? [],
      initFetchDataResponse?.clientProfile?.preferredCarMakes ?? []
    )
    setCarMakesList(orderedMakesList)
    setStaticBodyTypeGroupsEnum(initFetchDataResponse?.clientProfile?.bodyTypeGroups)
    // @ts-expect-error
    setCarMakeValue(initFetchDataResponse?.clientProfile?.mainCarMake.id)
  })

  useEffect(() => {
    //eslint-disable-next-line no-extra-semi
    ;(async () => {
      if (!carMakeValue) {
        return
      }
      setCarModelZeroOptionsLabel(getTranslation('basic.filter.dropdown.model.zero.loading'))
      const variables = {
        lang: LANGUAGE,
        basicFilter: {
          ...basicFilter(
            // @ts-expect-error
            params
          ),
          regionCountry: COUNTRY_CODE,
        },
        filter: {
          ...basicFilter(
            // @ts-expect-error
            params
          ),
          make: null,
          model: null,
          regionCountry: COUNTRY_CODE,
        },
        carFuelTypeFilter: {
          ...basicFilter(
            // @ts-expect-error
            params
          ),
          fuelTypes: null,
          regionCountry: COUNTRY_CODE,
        },
        carModelFilter: {
          ...basicFilter(
            // @ts-expect-error
            params
          ),
          model: null,
          regionCountry: COUNTRY_CODE,
        },
        carBodyFilterMakeIds: carMakeValue ? [carMakeValue] : [],
        carBodyFilterModelIds: carModelValue ? [carModelValue] : null,
      }
      /*
       * WHAT IS THE PROBLEM WITH THIS CODE???
       * WHY we can't use `fetchQuery(...)` from relay environment?
       * its really tricky weird behavior
       * relay normalize responses from backend and store them by key:value
       * each key for relay cache is `id` of every node...
       * -> that mean, that id should be uniq cross whole application
       * problem with next query is that purposeFilterConfig.itemsConfig.option.id is not unique
       * so when you fetch 2 different items with same IDs relay will normalize data
       * and remove duplicity nodes by ID -> so real result of `fetchQuery(...)` will have one
       * data result for one node ID
       *
       * that mean that if we want fetch this data 1:1 same as real backend response we can't
       * use relay fetchQuery and we have to use non relay graphql http fetch library
       *
       * how to fix it?
       * -> the best options is to change graphql schema and make it relay valid
       * -> it enable to use relay cache and correct behavior of relay normalization
       */
      try {
        const response = await request<BasicFilter_RefetchInputsQueryQuery>(
          API_URL,
          gql`
            query BasicFilter_refetchInputsQuery(
              $carFuelTypeFilter: CarFilterInput!
              $carModelFilter: CarFilterInput!
              $basicFilter: CarFilterInput!
              $lang: Lang!
              $carBodyFilterMakeIds: [ID!]!
              $carBodyFilterModelIds: [ID!]
            ) {
              fuelTypeCodeBook: codeBook(
                codeBookName: FUEL_TYPE
                includeUnused: false
                filter: $carFuelTypeFilter
              ) {
                id
                name: value(lang: $lang)
                carsCount
              }
              bodyTypeCodeBook: codeBook(
                codeBookName: BODY_TYPE
                includeUnused: false
                filter: $basicFilter
              ) {
                id
                name: value(lang: $lang)
                carsCount
              }
              # TODO: implement...
              carModels(includeUnused: false, filter: $carModelFilter) {
                id
                name: modelName
                carsCount
              }
              carBodyTypes(make: $carBodyFilterMakeIds, model: $carBodyFilterModelIds) {
                id
              }
              carsCount(filter: $basicFilter)
            }
          `,
          variables
        )

        setCarModelsDropdownValues(sortByNestedName(response.carModels))
        setCarModelZeroOptionsLabel(getTranslation('basic.filter.dropdown.model.zero.unavailable'))
        setCarFuelTypesList((response.fuelTypeCodeBook ?? []).filter(notNullable))
        setCarBodyTypesList((response.bodyTypeCodeBook ?? []).filter(notNullable))
        setCarsCount(response.carsCount)
        setCarBodyTypeByParamGroupsList(response.carBodyTypes)
      } catch (err) {
        console.error('err')
        // TODO: add UI fetch error
      }
    })()
  }, [
    carMakeValue,
    carModelValue,
    carPriceFromValue,
    carPriceToValue,
    carMileageFromValue,
    carMileageToValue,
    firstRegistrationFromValue,
    firstRegistrationToValue,
    selectCarBodyType,
    carFuelType,
    selectedCarBodyTypeGroupNames,
  ])

  /**
   * this function take fetched enum from server and by groupName finds
   * all bodyType ids for filter
   **/
  const getSelectedCarBodyTypeIdsByCarBodyGroups = () => {
    const carBodyTypeIds = carBodyTypeByParamGroupsList.map(bodyType => bodyType.id)
    return (staticBodyTypeGroupsEnum ?? [])
      .filter(({ name }) => selectedCarBodyTypeGroupNames.includes(name))
      .map(({ includedBodyTypes }) => includedBodyTypes?.map(bodyType => bodyType.id))
      .flat()
      .filter(notNullable)
      .filter(carBodyTypeId => carBodyTypeIds.includes(carBodyTypeId))
  }

  const selectedCarBodyTypeIdsByCarBodyGroups = getSelectedCarBodyTypeIdsByCarBodyGroups()
  const carBodyTypes = [
    ...(selectCarBodyType ? [selectCarBodyType] : []),
    ...(selectedCarBodyTypeIdsByCarBodyGroups ?? []),
  ]

  const params = {
    make: carMakeValue,
    model: carModelValue,
    priceRange: {
      min: carPriceFromValue,
      max: carPriceToValue,
    },
    mileageRange: {
      min: carMileageFromValue,
      max: carMileageToValue,
    },
    bodyTypes: carBodyTypes,
    fuelTypes: carFuelType,
    firstRegistration:
      LANGUAGE === 'SK'
        ? {
            min: firstRegistrationFromValue,
            max: firstRegistrationToValue,
          }
        : undefined,
  }

  const paramsForUrl: [string, any][] = [
    ['make', carMakeValue ? carMakeValue : null],
    ['model', carModelValue ? carModelValue : null],
    ['priceRangeFrom', carPriceFromValue ? carPriceFromValue : null],
    ['priceRangeTo', carPriceToValue ? carPriceToValue : null],
    ['mileageRangeFrom', carMileageFromValue ? carMileageFromValue : null],
    ['mileageRangeTo', carMileageToValue ? carMileageToValue : null],
    ['isUsed', true],
    ['isOneYearCar', true],
    ['isSkodaPlus', true],
    ['isDemo', LANGUAGE === 'SK'],
    ...(carBodyTypes ?? []).map(
      carBodyType => ['bodyTypes', carBodyType] as [string, typeof carBodyType]
    ),
    ['fuelTypes', carFuelType === 'null' || undefined ? null : carFuelType],
  ]

  if (LANGUAGE === 'SK') {
    paramsForUrl.push([
      'firstRegistrationFrom',
      firstRegistrationFromValue ? firstRegistrationFromValue : null,
    ])
    paramsForUrl.push([
      'firstRegistrationTo',
      firstRegistrationToValue ? firstRegistrationToValue : null,
    ])
  }

  const url = buildUrl('list', paramsForUrl).toString()

  const handleRangeChange = (
    value: [number | null | undefined, number | null | undefined],
    name?: string | undefined
  ) => {
    if (name === 'priceRange') {
      setCarPriceFrom(value[0] as number)
      setCarPriceTo(value[1])
    } else if (name === 'mileageRange') {
      setCarMileageFrom(value[0] as number)
      setCarMileageTo(value[1])
    } else if (name === 'firstRegistration') {
      setFirstRegistrationFrom(value[0] as number)
      setFirstRegistrationTo(value[1])
    }
  }

  const resetCarMakeUserState = useCallback(() => {
    setCarModel(null)
    setSelectedCarBodyTypeGroupNames([])
    setCarModelsDropdownValues([])
    setCarBodyTypesList([])
    setSelectCarBodyType(null)
  }, [])

  /**
   * -> match of 2 gql queries (static enum + dynamic data)
   * -> this function take fetched enum from server and find groupName for bodyTypes
   **/
  const getAvailableBodyTypeGroups = () => {
    const carBodyTypeIds = carBodyTypeByParamGroupsList.map(bodyType => bodyType.id)
    return (staticBodyTypeGroupsEnum ?? []).filter(staticBodyTypeGroupEnum => {
      const includedBodyTypes = staticBodyTypeGroupEnum.includedBodyTypes
      return includedBodyTypes?.some(bodyType => carBodyTypeIds.includes(bodyType.id))
    })
  }

  const availableBodyTypeGroups = getAvailableBodyTypeGroups()

  const availableCarBodyGroups = availableBodyTypeGroups?.map(bodyCarGroup => ({
    carBodyGroupName: bodyCarGroup.name,
    includedBodyTypes: bodyCarGroup.includedBodyTypes,
    icon: mappedCarBodyIconToName[bodyCarGroup.name as keyof typeof mappedCarBodyIconToName],
  }))

  const sliderCustomClass =
    LANGUAGE === 'SK'
      ? `saps-mb-40 ${basicView ? 'col-12 saps-mb-20' : 'col-12 col-md-6'}`
      : `${basicView ? 'col-12 d-none d-md-block' : 'col-12 col-md-6'}`
  const sliderCustomLabelClass =
    LANGUAGE === 'SK'
      ? `saps-slider-label--basic-filter saps-mb-10 saps-mb-0-md`
      : `saps-slider-label--basic-filter`

  return (
    <div className={basicView ? 'saps-pt-45' : 'saps-pt-40'}>
      <div className='row saps-mb-20-md'>
        <div
          className={
            basicView
              ? 'col-12 col-md saps-border-right-md'
              : 'col-12 col-md-6 col-lg-3 saps-border-right-md'
          }
        >
          <label className='saps-label saps-label--basic-filter saps-inputlist-dropdown__label saps-mb-5'>
            {getTranslation('basic.filter.dropdown.make.label')}
          </label>
          <InputRadioDropdown
            name={getTranslation('basic.filter.dropdown.make.label')}
            options={carMakesList}
            customCSSClass={`saps-basicfilter-main-dropdown`}
            selectedOptionID={carMakeValue}
            handleChange={e => {
              resetCarMakeUserState()
              setCarMakeValue(e.target.value)
            }}
            canDeselect={false}
          />
          {basicView && (
            <hr className='saps-hr saps-hr--basic-filter d-block d-md-none saps-mb-40 saps-mt-15-md' />
          )}
        </div>

        <div
          className={
            basicView
              ? 'col-12 col-md'
              : 'col-12 col-md-6 col-lg-3 saps-mt-30 saps-mt-0-md saps-border-right-lg'
          }
        >
          <label className='saps-label saps-label--basic-filter saps-inputlist-dropdown__label saps-mb-5'>
            {getTranslation('basic.filter.dropdown.model.label')}
          </label>
          <div>
            <InputRadioDropdown
              name={getTranslation('basic.filter.dropdown.model.label')}
              labelForEmpty={carModelDropdownEmptyLabel ?? carModelZeroOptionsLabel}
              options={carModelsDropdownValues}
              customCSSClass={`saps-basicfilter-main-dropdown saps-basic-filter--mobile`}
              selectedOptionID={carModelValue}
              aCSSClass='saps-basic-filter--mobile'
              handleChange={e => {
                setCarModel(e.target.value)
              }}
            />
          </div>
        </div>
        {!basicView && (
          <div className='col-12 col-md-6 col-lg-3 saps-mt-30 saps-mt-0-lg saps-border-right-md'>
            <label className='saps-label saps-label--basic-filter saps-inputlist-dropdown__label saps-mb-5'>
              {getTranslation('basic.filter.dropdown.fuelType.label')}
            </label>
            <div>
              <InputRadioDropdown
                name={getTranslation('basic.filter.dropdown.fuelType.label')}
                labelForEmpty={getTranslation('basic.filter.dropdown.fuelType.choose')}
                options={carFuelTypesList}
                customCSSClass={`saps-basicfilter-main-dropdown`}
                selectedOptionID={carFuelType}
                aCSSClass='saps-basic-filter--mobile'
                handleChange={e => {
                  setCarFuelType(e.target.value)
                }}
              />
            </div>
          </div>
        )}
        {!basicView && (
          <div className='col-12 col-md-6 col-lg-3 saps-mt-30 saps-mt-0-lg'>
            <label className='saps-label saps-label--basic-filter saps-inputlist-dropdown__label saps-mb-5'>
              {getTranslation('basic.filter.dropdown.bodyType.label')}
            </label>
            <div>
              <InputRadioDropdown
                name={getTranslation('basic.filter.dropdown.bodyType.label')}
                labelForEmpty={getTranslation('basic.filter.dropdown.bodyType.choose')}
                options={carBodyTypesList}
                customCSSClass={`saps-basicfilter-main-dropdown`}
                selectedOptionID={selectCarBodyType}
                aCSSClass='saps-basic-filter--mobile'
                handleChange={e => {
                  setSelectCarBodyType(e.target.value)
                }}
              />
            </div>
          </div>
        )}
      </div>

      {basicView && <hr className='saps-hr saps-hr--basic-filter' />}

      <div
        className={`row saps-mb-50-md flex-nowrap ${
          isMobile ? 'saps-mt-30' : basicView ? 'saps-mt-20' : 'saps-mt-85'
        }`}
      >
        <div className='col'>
          {basicView && (
            <>
              <div className='row saps-pb-15'>
                <div className={`${isMobile ? 'col-12' : 'col-auto'}`}>
                  <label className='saps-label saps-label--basic-filter saps-inputlist-dropdown__label saps-mb-5'>
                    {getTranslation('basic.filter.dropdown.fuelType.label')}
                  </label>
                  <div>
                    <InputRadioDropdown
                      name={getTranslation('basic.filter.dropdown.fuelType.label')}
                      labelForEmpty={getTranslation('basic.filter.dropdown.fuelType.choose')}
                      options={carFuelTypesList}
                      customCSSClass={`saps-basicfilter-main-dropdown`}
                      selectedOptionID={carFuelType}
                      handleChange={e => {
                        setCarFuelType(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className='col d-none d-md-block'>
                  <BasicFilterBodyTypeGroups
                    bodyTypesList={availableCarBodyGroups ?? []}
                    selectedCarBodyTypeGroupNames={selectedCarBodyTypeGroupNames}
                    updateBodyTypes={(carBodyGroupName, isChecked) => {
                      let newSelectedCarBodyTypeGroupNames
                      if (isChecked) {
                        newSelectedCarBodyTypeGroupNames = [
                          ...selectedCarBodyTypeGroupNames,
                          carBodyGroupName,
                        ]
                      } else {
                        newSelectedCarBodyTypeGroupNames = selectedCarBodyTypeGroupNames.filter(
                          selectedBodyTypeGroup => selectedBodyTypeGroup !== carBodyGroupName
                        )
                      }
                      setSelectedCarBodyTypeGroupNames(newSelectedCarBodyTypeGroupNames)
                    }}
                  />
                </div>
              </div>
              <hr className='saps-hr saps-hr--basic-filter saps-mb-30' />
            </>
          )}
          <div className='row'>
            <InputSlider
              title={getTranslation('basic.filter.slider.price.label')}
              name={'priceRange'}
              units={getTranslation('basic.filter.slider.price.unit')}
              minValue={0}
              maxValue={LANGUAGE === 'SK' ? 100000 : 1000000}
              minLabel={`0`}
              maxLabel={getTranslation('basic.filter.slider.price.max')}
              selectedValueFrom={carPriceFromValue}
              selectedValueTo={carPriceToValue}
              handleRangeChange={handleRangeChange}
              customClass={`saps-mb-40 ${basicView ? 'col-12 saps-mb-20' : 'col-12 col-md-6'}`}
              thousandDelimiter={true}
              customLabelCSSClass={`saps-slider-label--basic-filter saps-mb-10 saps-mb-0-md`}
            />
            <InputSlider
              title={getTranslation('basic.filter.slider.mileage.label')}
              name={'mileageRange'}
              units={getTranslation('basic.filter.slider.mileage.unit')}
              minValue={0}
              maxValue={300000}
              minLabel={`0`}
              maxLabel={getTranslation('basic.filter.slider.mileage.max')}
              selectedValueFrom={carMileageFromValue}
              selectedValueTo={carMileageToValue}
              handleRangeChange={handleRangeChange}
              customClass={sliderCustomClass}
              thousandDelimiter={true}
              customLabelCSSClass={sliderCustomLabelClass}
              customStep={10000}
            />
            {LANGUAGE === 'SK' && (
              <InputSlider
                title={getTranslation('basic.filter.slider.firstRegistration.label')}
                name={'firstRegistration'}
                units={``}
                minValue={MIN_FIRST_REG}
                maxValue={MAX_FIRST_REG}
                minLabel={`1990`}
                maxLabel={`${new Date().getFullYear()}`}
                selectedValueFrom={firstRegistrationFromValue}
                selectedValueTo={firstRegistrationToValue}
                handleRangeChange={handleRangeChange}
                customClass={sliderCustomClass}
                customLabelCSSClass={sliderCustomLabelClass}
                customStep={1}
              />
            )}
          </div>
        </div>
      </div>

      <div className='row saps-mb-20 saps-mt-40 saps-filter-button-align'>
        <div className={`${basicView ? 'col-6' : 'col-md-4'} d-none d-md-block`}>
          <SPLink href={url} className='saps-button saps-button--link'>
            <span className='saps-color-secondary saps-mr-10'>
              {getTranslation('basic.filter.button.advanced')}
            </span>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' height='13' width='8'>
              <path
                d='M52.5 419L219 252.5 52.5 86q-4.5-4-10.25-4T32 86q-4 4-4 10t4 10l146.5 146.5L32 399q-4 4-4 10t4 10q4 4 9.75 4t10.75-4z'
                fill='#ef7d00'
              />
            </svg>
          </SPLink>
        </div>
        <div className={basicView ? 'col-12 col-md-6' : 'col-md-4 text-center'}>
          <LinkButton
            onClick={() => {
              gtmDataLayerPush({
                event: 'trackEvent',
                eventCategory: 'Microsite - skodaplus',
                eventAction: 'Filter',
                eventLabel: 'Vím, jaký vůz chci',
                'appweb.Name': 'ms_filter_vim_jaky_vuz_chci',
              })
            }}
            url={url}
            title={getTranslation('basic.filter.button.redirect.label')}
            className={'saps-button--50-tall saps-button--40-tall-md'}
            disabled={carsCount !== undefined && carsCount === 0}
            style={{ minWidth: '240px' }}
          >
            {carsCount || carsCount === 0
              ? getTranslation('basic.filter.submit.button.text', [
                  { key: 'carsCount', value: carsCount },
                  { key: 'carsLabel', value: oneToFive(carsCount) },
                ])
              : defaultSubmitButtonText}
          </LinkButton>
        </div>
        <div
          className={
            basicView
              ? 'col-12 col-md-6 d-md-none saps-mt-20'
              : 'col-12 col-md-4 d-md-none saps-mt-20'
          }
        >
          <LinkButton
            url={url}
            title={getTranslation('basic.filter.button.advanced')}
            className={'saps-button--50-tall saps-button-grey-6--outline'}
            style={{ minWidth: '240px' }}
          >
            {getTranslation('basic.filter.button.advanced')}
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default BasicFilter
