/**
 * @generated SignedSource<<a8b91de7f2d1f0757703cb24ad58ef45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CarsOrderBy = "CREATED_ASC" | "CREATED_DESC" | "FIRST_REGISTRATION_ASC" | "FIRST_REGISTRATION_DESC" | "MILEAGE_ASC" | "MILEAGE_DESC" | "PRICE_ASC" | "PRICE_DESC" | "%future added value";
export type DemoCarTypeInput = "EMPTY" | "FOR_SALE" | "ON_REQUEST" | "%future added value";
export type RegionCountry = "CZ" | "SK" | "%future added value";
export type CarFilterInput = {
  bodyTypes?: ReadonlyArray<string> | null | undefined;
  carsOrderBy?: CarsOrderBy | null | undefined;
  colors?: ReadonlyArray<string> | null | undefined;
  dealers?: ReadonlyArray<string> | null | undefined;
  demoCarTypes?: ReadonlyArray<DemoCarTypeInput | null | undefined> | null | undefined;
  doorsCountRange?: InputIntRange | null | undefined;
  engineCapacity?: InputIntRange | null | undefined;
  enginePower?: InputIntRange | null | undefined;
  equipmentItems?: ReadonlyArray<string> | null | undefined;
  equipmentLevels?: ReadonlyArray<string> | null | undefined;
  firstOwner?: boolean | null | undefined;
  firstRegistration?: InputIntRange | null | undefined;
  fuelTypes?: ReadonlyArray<string> | null | undefined;
  hasServiceBook?: boolean | null | undefined;
  instalmentRange?: InputIntRange | null | undefined;
  isNew?: boolean | null | undefined;
  make?: ReadonlyArray<string | null | undefined> | null | undefined;
  metallicPaint?: boolean | null | undefined;
  mileageRange?: InputIntRange | null | undefined;
  model?: ReadonlyArray<string | null | undefined> | null | undefined;
  oneYearCar?: boolean | null | undefined;
  priceRange?: InputIntRange | null | undefined;
  regionCountry?: RegionCountry | null | undefined;
  regions?: ReadonlyArray<string> | null | undefined;
  seatsCountRange?: InputIntRange | null | undefined;
  showOnHomepage?: boolean | null | undefined;
  skodaPlus?: boolean | null | undefined;
  skofinOffers?: ReadonlyArray<string> | null | undefined;
  transmissionTypes?: ReadonlyArray<string> | null | undefined;
  usedCar?: boolean | null | undefined;
};
export type InputIntRange = {
  max?: number | null | undefined;
  min?: number | null | undefined;
};
export type BasicFilter_initFetchDataQuery$variables = {
  filter?: CarFilterInput | null | undefined;
};
export type BasicFilter_initFetchDataQuery$data = {
  readonly carMakes: ReadonlyArray<{
    readonly carsCount: number;
    readonly id: string;
    readonly name: string;
  }>;
  readonly clientProfile: {
    readonly bodyTypeGroups: ReadonlyArray<{
      readonly includedBodyTypes: ReadonlyArray<{
        readonly id: string;
      }> | null | undefined;
      readonly name: string;
    }> | null | undefined;
    readonly mainCarMake: {
      readonly id: string;
    };
    readonly preferredCarMakes: ReadonlyArray<string> | null | undefined;
  };
};
export type BasicFilter_initFetchDataQuery = {
  response: BasicFilter_initFetchDataQuery$data;
  variables: BasicFilter_initFetchDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "clientId",
        "value": "SKODAPLUS"
      }
    ],
    "concreteType": "ClientProfile",
    "kind": "LinkedField",
    "name": "clientProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BodyTypeGroup",
        "kind": "LinkedField",
        "name": "bodyTypeGroups",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BodyType",
            "kind": "LinkedField",
            "name": "includedBodyTypes",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "preferredCarMakes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Make",
        "kind": "LinkedField",
        "name": "mainCarMake",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": "clientProfile(clientId:\"SKODAPLUS\")"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Literal",
        "name": "includeUnused",
        "value": false
      }
    ],
    "concreteType": "Make",
    "kind": "LinkedField",
    "name": "carMakes",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "carsCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BasicFilter_initFetchDataQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BasicFilter_initFetchDataQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "53050ad1af684475dcc9193d1e99cb34",
    "id": null,
    "metadata": {},
    "name": "BasicFilter_initFetchDataQuery",
    "operationKind": "query",
    "text": "query BasicFilter_initFetchDataQuery(\n  $filter: CarFilterInput\n) {\n  clientProfile(clientId: \"SKODAPLUS\") {\n    bodyTypeGroups {\n      name\n      includedBodyTypes {\n        id\n      }\n    }\n    preferredCarMakes\n    mainCarMake {\n      id\n    }\n  }\n  carMakes(includeUnused: false, filter: $filter) {\n    id\n    name\n    carsCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1726c04e5a9078ff614f4cd0bb5683ef";

export default node;
