import { SaplusResizeProvider } from '../splus-lib/utils'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { getTranslation } from '../splus-lib/utils/languageUtils'
import BasicFilter from './BasicFilter'
import PurposeFilter from './PurposeFilter'
import React from 'react'

type Props = {
  basicView: boolean
  equipmentData: any
}

const Root = ({ basicView, equipmentData }: Props) => {
  return (
    <SaplusResizeProvider>
      <div
        className={
          basicView
            ? 'saps-basic-filter saps-basic-filter--basic-view saps-pt-40 saps-pt-0-md saps-pb-40 saps-pb-0-md'
            : 'saps-basic-filter saps-basic-filter--wide-view saps-pt-40 saps-pb-40'
        }
      >
        <div className={basicView ? '' : 'container'}>
          <Tabs forceRenderTabPanel>
            <TabList
              className='saps-tab-list saps-basic-filter-main-tabs saps-mb-20'
              data-component='basic-filter-main-tabs'
            >
              <Tab
                className='saps-tab-title saps-tab-title--basic-filter'
                selectedClassName='saps-tab-title--active'
                data-type='tab'
                data-name='IKnowWhatCar'
              >
                {getTranslation('basic.filter.tab1')}
              </Tab>
              <Tab
                className='saps-tab-title saps-tab-title--basic-filter'
                selectedClassName='saps-tab-title--active'
                data-type='tab'
                data-name='IKnowPurposeOfCar'
              >
                {getTranslation('basic.filter.tab2')}
              </Tab>
            </TabList>
            <TabPanel>
              <BasicFilter basicView={basicView} />
            </TabPanel>
            <TabPanel>
              <PurposeFilter equipmentData={equipmentData} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </SaplusResizeProvider>
  )
}

export default Root
