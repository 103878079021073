import { Breakpoint } from 'react-socks'
import { COUNTRY_CODE } from '../splus-lib/utils/languageUtils'
import { QueryRenderer, ReactRelayContext, graphql } from 'react-relay'
import { RootWidgetRecommendedModelsQueryQuery } from './Root.__generated__'
import { getRecommendedModelsByCars } from '../splus-lib/utils'
import React, { useContext } from 'react'
import RecommendedModels from './RecommendedModels'

type Props = {
  data: { title: string }
}

const Root = (props: Props) => {
  const { environment } = useContext(ReactRelayContext)!

  return (
    <React.Fragment>
      <Breakpoint medium up>
        <QueryRenderer<{
          variables: any
          rawResponse: any
          response: RootWidgetRecommendedModelsQueryQuery
        }>
          environment={environment}
          variables={{
            filter: {
              regionCountry: COUNTRY_CODE,
            },
          }}
          query={graphql`
            query RootWidgetRecommendedModelsQuery($filter: CarFilterInput) {
              carModels(includeUnused: true, filter: $filter) {
                id
                modelName
                carsCount
                carMake {
                  id
                }
              }
              clientProfile(clientId: "SKODAPLUS") {
                mainCarMake {
                  id
                }
              }
            }
          `}
          render={({ error, props: renderProps }) => {
            if (error) {
              return null
            } else if (props) {
              const mainCarMake = renderProps?.clientProfile?.mainCarMake?.id
              const cars = getRecommendedModelsByCars(renderProps?.carModels ?? [], mainCarMake!)
              return <RecommendedModels cars={cars} title={props.data.title} />
            }
            return null
          }}
        />
      </Breakpoint>
      <Breakpoint small down />
    </React.Fragment>
  )
}

export default Root
