import * as R from 'ramda'
import {
  API_URL,
  COUNTRY_CODE,
  LANGUAGE,
  getTranslation,
  oneToFive,
} from '../splus-lib/utils/languageUtils'
import { ReactComponent as Checkmark } from './svg/checkmark.svg'
import { ReactComponent as Family } from './svg/baby.svg'
import { ReactComponent as Fun } from './svg/fun.svg'
import {
  InputRadioDropdown,
  InputSlider,
  LinkButton,
  ShowInfoLightBox,
} from '../splus-lib/components'
import {
  PurposeFilter_CarsCountQueryQuery,
  PurposeFilter_ClientProfileQueryQuery,
  PurposeFilter_ClientProfileQueryQueryVariables,
} from './PurposeFilter.__generated__'
import { ReactRelayContext, fetchQuery, graphql } from 'react-relay'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { ReactComponent as Transportation } from './svg/transportation.svg'
import { ReactComponent as Travel } from './svg/travel.svg'
import { ReactComponent as Work } from './svg/work.svg'
import {
  filterOutEmptyObjValues,
  filterTransmissionList,
  groupTransmissionIds,
  notNullable,
  ungroupTransmissionIds,
} from '../splus-lib/utils'
import { gql, request } from 'graphql-request'
import { gtmDataLayerPush } from '../splus-lib/utils/googleTagManager/utils'
import React, { useContext, useEffect, useState } from 'react'

const PurposeFilter_ClientProfileQueryGQL = gql`
  query PurposeFilter_ClientProfileQuery($clientId: String!, $lang: Lang!) {
    clientProfile(clientId: $clientId) {
      purposeFilterConfig {
        name
        itemsConfig {
          name
          options {
            ... on IntRangeFilterItemOption {
              name
              min
              max
              isSelected
              __typename
            }
            ... on CodebookFilterItemOption {
              codeBookValue: value {
                id
                value(lang: $lang)
                type
              }
              isSelected
              __typename
            }
            ... on CarEquipmentFilterItemOption {
              id
              value(lang: $lang)
              isSelected
              __typename
            }
          }
        }
      }
    }
  }
`

const NON_SELECTED_OPTION = 'NON_SELECTED_OPTION'

const getTabValues = (
  purposeFilterConfig: NonNullable<
    PurposeFilter_ClientProfileQueryQuery['clientProfile']['purposeFilterConfig']
  >
) =>
  purposeFilterConfig.map(tab => ({
    name: tab.name,
    // rename to values
    itemsConfig: (tab.itemsConfig ?? [])
      .map(input => {
        // All tabTypename are the same for each array item
        const tabTypename = input?.options?.[0]?.__typename
        switch (tabTypename) {
          case 'CodebookFilterItemOption': {
            // TODO: gql schema is not properly defined. All array items have to have the same __typename

            const options = input.options as any[] // PurposeFilter_ClientProfileQueryQuery['clientProfile']['purposeFilterConfig'][number] _clientProfile_purposeFilterConfig_itemsConfig_options_CodebookFilterItemOption[]
            let codeBookOptions = options.map(option => option.codeBookValue)
            let selectedOption = options.find(option => option.isSelected === true)

            // HACK: patch values for transmissions
            // tricky weird business logic case
            if (input.name === 'transmission') {
              if (selectedOption) {
                const groupedTransmissionsValue = groupTransmissionIds([
                  selectedOption.codeBookValue.id,
                ])
                selectedOption = {
                  ...selectedOption,
                  codeBookValue: {
                    ...selectedOption.codeBookValue,
                    id: groupedTransmissionsValue[0],
                  },
                }
              }
              codeBookOptions = filterTransmissionList(codeBookOptions)
            }

            if (selectedOption) {
              return {
                __typename: tabTypename as 'CodebookFilterItemOption',
                name: input.name,
                type: selectedOption.codeBookValue.type,
                value: selectedOption.codeBookValue.id,
              }
            } else {
              return {
                __typename: tabTypename as 'CodebookFilterItemOption',
                name: input.name,
                type: codeBookOptions[0].type,
                value: 'Nil',
              }
            }
          }

          case 'CarEquipmentFilterItemOption': {
            // TODO: gql schema is not properly defined. All array items have to have the same __typename

            const options = input.options as any[]
            const foundSelectedOption = options.find(item => item.isSelected === true)
            if (input.options?.length === 1) {
              return {
                __typename: tabTypename as 'CarEquipmentFilterItemOption',
                name: input.name,
                value: foundSelectedOption ? foundSelectedOption?.id : NON_SELECTED_OPTION,
              }
            }
            return {
              __typename: tabTypename as 'CarEquipmentFilterItemOption',
              name: input.name,
              value: foundSelectedOption ? foundSelectedOption?.id : options[0]?.id,
            }
          }
          case 'IntRangeFilterItemOption': {
            // TODO: gql schema is not properly defined. All array items have to have the same __typename

            const options = input.options as any[]
            const selectedOption = options.find(item => item.isSelected === true)
            return {
              __typename: tabTypename as 'IntRangeFilterItemOption',
              name: input.name,
              value: [selectedOption?.min, selectedOption?.max],
            }
          }

          default:
            return undefined
        }
      })
      .filter(notNullable),
  }))

type TabValue = ReturnType<typeof getTabValues>[number]

type PriceRange = [number | undefined | null, number | undefined | null]

const buildUrlAndFilterByUserConf = (currentTabValues: TabValue, priceRange: PriceRange) => {
  if (!currentTabValues) {
    return undefined
  }

  const newUrlState = new URL(`${window.location.origin}${window.location.pathname}`)
  const filterData = {
    equipmentItems: [] as string[],
    colors: [] as string[],
    transmissionTypes: [] as string[],
    fuelTypes: undefined as undefined | string,
    bodyTypes: undefined as undefined | any,
    enginePower: undefined as any,
    doorsCountRange: undefined as any,
    mileageRange: {
      min: null as null | number,
      max: null as null | number,
    },
    seatsCountRange: {
      min: null as null | number,
      max: null as null | number,
    },
    priceRange: {
      min: priceRange[0],
      max: priceRange[1],
    },
  }

  // price range url handler
  if (priceRange[0] !== null && priceRange[0] !== undefined) {
    newUrlState.searchParams.set(`priceRangeFrom`, priceRange[0].toString())
  }
  if (priceRange[1] !== null && priceRange[1] !== undefined) {
    newUrlState.searchParams.set(`priceRangeTo`, priceRange[1].toString())
  }

  // forms configs
  currentTabValues.itemsConfig.forEach(filterConf => {
    switch (filterConf.__typename) {
      case 'IntRangeFilterItemOption': {
        let mapRangeName = '' as keyof typeof filterData
        switch (filterConf.name) {
          case 'mileage':
            mapRangeName = 'mileageRange'
            break
          case 'seats-count':
            mapRangeName = 'seatsCountRange'
            break
          case 'engine-power':
            mapRangeName = 'enginePower'
            break
          case 'doors-count':
            mapRangeName = 'doorsCountRange'
            break
          default:
            // @ts-expect-error
            mapRangeName = filterConf.name
            break
        }
        const [rangeMin, rangeMax] = filterConf.value
        if (rangeMin !== null && rangeMin !== undefined) {
          newUrlState.searchParams.set(`${mapRangeName}From`, rangeMin.toString())
        }
        if (rangeMax !== null && rangeMax !== undefined) {
          newUrlState.searchParams.set(`${mapRangeName}To`, rangeMax.toString())
        }

        filterData[mapRangeName] = {
          min: rangeMin as number,
          max: rangeMax as number,
        }
        break
      }
      case 'CarEquipmentFilterItemOption': {
        if (filterConf?.value !== NON_SELECTED_OPTION && filterConf?.value !== 'Nil') {
          newUrlState.searchParams.append('equipmentItems', filterConf.value)
          if (!filterData.equipmentItems) {
            filterData.equipmentItems = []
          }
          ;(filterData.equipmentItems as string[]).push(filterConf.value)
        }
        break
      }
      case 'CodebookFilterItemOption': {
        // tricky weird business logic case
        if (filterConf.name === 'transmission') {
          const transmissionValueIds = ungroupTransmissionIds([filterConf.value as string])
          transmissionValueIds.forEach(transmissionId => {
            newUrlState.searchParams.append('transmissionTypes', transmissionId)
          })
          if (transmissionValueIds.length > 0) {
            filterData.transmissionTypes = transmissionValueIds
          }
        } else {
          let mapCodeBookItem = '' as keyof typeof filterData
          switch (filterConf.type) {
            case 'FUEL_TYPE':
              mapCodeBookItem = 'fuelTypes'
              break
            case 'COLOR':
              mapCodeBookItem = 'colors'
              break
            case 'BODY_TYPE':
              mapCodeBookItem = 'bodyTypes'
              break
            default:
              // @ts-expect-error
              mapCodeBookItem = filterConf.name
              break
          }
          if (filterConf.value && filterConf.value !== 'Nil') {
            newUrlState.searchParams.append(mapCodeBookItem, filterConf.value as string)
            filterData[mapCodeBookItem] = filterConf.value as string
          }
        }
        break
      }
      default:
        return
    }
  })

  return {
    filterData,
    urlSearch: newUrlState.search,
  }
}

type Props = {
  equipmentData: any
}

const PurposeFilter = (props: Props) => {
  const { environment } = useContext(ReactRelayContext)!
  const [currentTab, setCurrentTab] = useState(null as number | null)

  const [priceRange, setPriceRange] = useState([null, null] as PriceRange)
  const [purposeFilterConfig, setPurposeFilterConfig] = useState(
    [] as NonNullable<PurposeFilter_ClientProfileQueryQuery['clientProfile']['purposeFilterConfig']>
  )
  const [tabValues, setTabValues] = useState([] as TabValue[])
  const [carsCount, setCarsCount] = useState<number | null>(null)

  const urlAndFilterParsed = buildUrlAndFilterByUserConf(tabValues[currentTab!], priceRange)

  useEffect(() => {
    //eslint-disable-next-line no-extra-semi
    ;(async () => {
      /*
       * WHAT IS THE PROBLEM WITH THIS CODE???
       * WHY we can't use `fetchQuery(...)` from relay environment?
       * its really tricky weird behavior
       * relay normalize responses from backend and store them by key:value
       * each key for relay cache is `id` of every node...
       * -> that mean, that id should be uniq cross whole application
       * problem with next query is that purposeFilterConfig.itemsConfig.option.id is not unique
       * so when you fetch 2 different items with same IDs relay will normalize data
       * and remove duplicity nodes by ID -> so real result of `fetchQuery(...)` will have one
       * data result for one node ID
       *
       * that mean that if we want fetch this data 1:1 same as real backend response we can't
       * use relay fetchQuery and we have to use non relay graphql http fetch library
       *
       * how to fix it?
       * -> the best options is to change graphql schema and make it relay valid
       *  -> it enable to use relay cache and correct behavior of relay normalization
       */

      const res = await request<
        PurposeFilter_ClientProfileQueryQuery,
        PurposeFilter_ClientProfileQueryQueryVariables
      >(API_URL, PurposeFilter_ClientProfileQueryGQL, {
        clientId: 'SKODAPLUS',
        lang: LANGUAGE,
      })

      // pureResWithoutRelay = (await pureReqWithoutRelay.json()).data
      const purposeFilterConfig = res.clientProfile.purposeFilterConfig

      setTabValues(getTabValues(purposeFilterConfig ?? []))
      setPurposeFilterConfig(
        // @ts-expect-error
        purposeFilterConfig
      )
    })()
  }, [])

  useEffect(() => {
    const main = async () => {
      if (!tabValues[currentTab!]) return
      const filter = {
        ...urlAndFilterParsed?.filterData,
        regionCountry: COUNTRY_CODE,
      }

      const query = gql`
        query PurposeFilter_CarsCountQuery($filter: CarFilterInput) {
          carsCount(filter: $filter)
        }
      `

      const data = await request<PurposeFilter_CarsCountQueryQuery>(API_URL, query, {
        filter: filterOutEmptyObjValues(filter!),
      })

      setCarsCount(data?.carsCount ?? null)
    }

    main()
  }, [priceRange, tabValues, currentTab])

  const icons = [
    <Family key='tab-icon-0' />,
    <Travel key='tab-icon-1' />,
    <Work key='tab-icon-2' />,
    <Transportation key='tab-icon-3' />,
    <Fun key='tab-icon-4' />,
  ]
  return (
    <div>
      <Tabs forceRenderTabPanel onSelect={selectedTab => setCurrentTab(selectedTab)}>
        <div className='saps-basicfilter-purpose-tabs'>
          <hr className='saps-hr saps-mb-15 d-none d-md-block' />
          <TabList className='saps-icontab-list' data-component='purpose-filter-tabs'>
            {purposeFilterConfig?.map((tab, tabIndex) => (
              <Tab
                key={`tab-${tab.name}`}
                className='saps-icontab-title'
                selectedClassName={currentTab !== null ? 'saps-icontab-title--active' : ''}
                data-type='tab'
                data-name={tab.name}
              >
                <span className='saps-icontab-title__icon'>{icons[tabIndex]}</span>
                <span className='saps-basicfilter-purpose-checkmark'>
                  <Checkmark />
                </span>
                <span className='saps-basicfilter-purpose-tab-label'>
                  {
                    // @ts-expect-error
                    getTranslation('purpose.filter.' + tab.name)
                  }
                </span>
              </Tab>
            ))}
          </TabList>
          <hr className='saps-hr saps-mb-25 d-none d-md-block' />
        </div>

        {currentTab !== null &&
          (purposeFilterConfig ?? []).map((tab, tabIndex) => (
            <TabPanel key={`tabPanel-${tabIndex}`}>
              <div className='row'>
                {tab.itemsConfig?.map((input, inputIndex) => {
                  const inputTypename = input.options?.[0]?.__typename
                  const selectedOptionID = tabValues[tabIndex].itemsConfig[inputIndex].value

                  if (inputTypename === 'CodebookFilterItemOption') {
                    // TODO: gql schema is not properly defined. All array items have to have the same __typename

                    const inputOptions = input.options as any[]
                    let options = inputOptions.map(option => option.codeBookValue)

                    // tricky weird business logic case
                    if (input.name === 'transmission') {
                      options = filterTransmissionList(options)
                    }

                    return (
                      <div className='col-12 col-md-6' key={`${tabIndex}-${input.name}`}>
                        <span className='saps-basicfilter-purpose-label'>
                          {
                            // @ts-expect-error
                            getTranslation('purpose.filter.' + input.name)
                          }
                        </span>
                        <InputRadioDropdown
                          key={`${tabIndex}-${input.name}`}
                          name={input.name}
                          options={[
                            ...options,
                            {
                              id: 'Nil',
                              name: getTranslation('purpose.filter.dropdown.not.important'),
                            },
                          ]}
                          selectedOptionID={selectedOptionID as string}
                          customCSSClass={`saps-basicfilter-purpose-dropdown saps-mb-20`}
                          labelForEmpty={getTranslation('purpose.filter.dropdown.choose')}
                          showNumberOfItems={false}
                          handleChange={e => {
                            const selectedValue = e.target.value
                            const newParams = R.assocPath(
                              [tabIndex, 'itemsConfig', inputIndex, 'value'],
                              selectedValue,
                              tabValues
                            )
                            setTabValues(newParams)
                          }}
                        />
                      </div>
                    )
                  }

                  if (inputTypename === 'CarEquipmentFilterItemOption') {
                    // TODO: gql schema is not properly defined. All array items have to have the same __typename

                    const inputOptions = input.options as any[]
                    let options = [] as { value: string | null; id: string }[]

                    // if there is only one option we let user to select
                    // YES or NO
                    if (inputOptions.length === 1) {
                      options = [
                        {
                          value: getTranslation('purpose.filter.yes'),
                          id: inputOptions[0]?.id,
                        },
                      ]
                    } else {
                      options = inputOptions
                    }
                    return (
                      <div className='col-12 col-md-6' key={`${tabIndex}-${input.name}`}>
                        <span className='saps-basicfilter-purpose-label'>
                          {getTranslation(
                            // @ts-expect-error
                            'purpose.filter.' + input.name
                          )}{' '}
                          {input.name === 'isofix' && (
                            <ShowInfoLightBox
                              smallSize
                              equipmentId={inputOptions?.[0]?.id}
                              equipmentData={props.equipmentData}
                            />
                          )}
                        </span>
                        <InputRadioDropdown
                          key={`${tabIndex}-${input.name}`}
                          name={input.name}
                          options={[
                            ...options,
                            {
                              value: getTranslation('purpose.filter.dropdown.not.important'),
                              id: NON_SELECTED_OPTION,
                            },
                          ]}
                          selectedOptionID={selectedOptionID as string}
                          customCSSClass={`saps-basicfilter-purpose-dropdown saps-mb-20`}
                          labelForEmpty={getTranslation('purpose.filter.dropdown.choose')}
                          showNumberOfItems={false}
                          handleChange={e => {
                            const newParams = R.assocPath(
                              [tabIndex, 'itemsConfig', inputIndex, 'value'],
                              e.target.value,
                              tabValues
                            )
                            setTabValues(newParams)
                          }}
                        />
                      </div>
                    )
                  }

                  if (inputTypename === 'IntRangeFilterItemOption') {
                    // TODO: gql schema is not properly defined. All array items have to have the same __typename

                    const inputOptions = input.options as any[]
                    const options = inputOptions.map((item, itemIndex) => ({
                      id: itemIndex,

                      name: getTranslation(
                        // @ts-expect-error
                        'purpose.filter.' + item.name
                      ) as string,
                      value: [item?.min, item?.max] as const,
                    }))
                    return (
                      <div className='col-12 col-md-6' key={`${tabIndex}-${input.name}`}>
                        <span className='saps-basicfilter-purpose-label'>
                          {
                            // @ts-expect-error
                            getTranslation('purpose.filter.' + input.name)
                          }
                        </span>

                        <InputRadioDropdown
                          key={`${tabIndex}-${input.name}`}
                          name={input.name}
                          options={options}
                          // to string
                          selectedOptionID={
                            // shit index code :no-mouth:
                            options.findIndex(
                              option =>
                                option.value[0] === selectedOptionID[0] &&
                                option.value[1] === selectedOptionID[1]
                            )
                          }
                          customCSSClass={`saps-basicfilter-purpose-dropdown saps-mb-20`}
                          labelForEmpty={getTranslation('purpose.filter.dropdown.choose')}
                          showNumberOfItems={false}
                          handleChange={e => {
                            const selectedIndex = parseInt(e.target.value ?? '')
                            const newParams = R.assocPath(
                              [tabIndex, 'itemsConfig', inputIndex, 'value'],
                              options[selectedIndex].value,
                              tabValues
                            )
                            setTabValues(newParams)
                          }}
                        />
                      </div>
                    )
                  }
                  return <div key={tabIndex} />
                })}
              </div>
            </TabPanel>
          ))}
      </Tabs>
      <div
        className={`${currentTab === null ? 'd-none' : ''} saps-mt-30-md  saps-filter-button-align`}
      >
        <div className='row'>
          <div className='col'>
            <InputSlider
              title={getTranslation('purpose.filter.slider.price.label')}
              name={'priceRange'}
              units={getTranslation('purpose.filter.slider.price.unit')}
              minValue={0}
              maxValue={LANGUAGE === 'SK' ? 100000 : 1000000}
              minLabel={`0`}
              maxLabel={getTranslation('purpose.filter.slider.price.max')}
              thousandDelimiter={true}
              selectedValueFrom={priceRange[0]}
              selectedValueTo={priceRange[1]}
              handleRangeChange={newRangeArr => setPriceRange(newRangeArr)}
              customClass={`saps-mb-30 saps-input-slider--purpose-filter`}
              customLabelCSSClass={`saps-color-grey-400 saps-color-grey-300-md saps-mb-10 saps-mb-0-md`}
            />
          </div>
        </div>
        <div className='row justify-content-center'>
          <LinkButton
            onClick={() => {
              gtmDataLayerPush({
                event: 'trackEvent',
                eventCategory: 'Microsite - skodaplus',
                eventAction: 'Filter',
                eventLabel: 'Vím, k čemu vůz chci',
                'appweb.Name': 'ms_filter_vim_k_cemu_vuz_chci',
              })
            }}
            style={{ minWidth: '240px' }}
            url={`/list${urlAndFilterParsed?.urlSearch ?? ''}`}
            title={getTranslation('purpose.filter.button.redirect')}
            className='saps-button--50-tall saps-button--40-tall-md'
          >
            {carsCount || carsCount === 0
              ? getTranslation('purpose.filter.submit.button', [
                  { key: 'carsCount', value: carsCount },
                  { key: 'carsLabel', value: oneToFive(carsCount) },
                ])
              : getTranslation('purpose.filter.submit.button.default')}
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default PurposeFilter
