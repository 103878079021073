import { getRecommendedModelsByCars } from '../splus-lib/utils'
import React from 'react'
import RecommendedModelCard from './RecommendedModelCard'
import markdownToHtml from '../../lib/markdownToHtml'
import styled from 'styled-components'

const DivTitle = styled.div`
  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.09;
    color: #000000;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 45px;
      line-height: 0.93;
    }
  }
`

type Props = {
  cars: ReturnType<typeof getRecommendedModelsByCars>
  title: string
}

const RecommendedModels = ({ cars, title }: Props) => (
  <div className='saps-recommended-models__wrapper'>
    <DivTitle>{markdownToHtml(title)}</DivTitle>
    <div className='saps-recommended-models'>
      {cars.map(car => (
        <RecommendedModelCard
          key={car.id}
          id={car.id}
          name={car.name}
          imageSrc={car.imageSrc}
          mainCarMake={car.mainCarMake}
          carsCount={car.carsCount}
        />
      ))}
    </div>
  </div>
)

export default RecommendedModels
